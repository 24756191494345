<template>
  <div>
    <!-- 跳转组 -->
    <div class="top">
      <div>
        <span class="l">挡位管理</span>
        <span style="margin-left:100px;color:#333">请选择场地</span>
        <el-select
          :disabled="!search"
          v-model="addresssearch"
          filterable
          placeholder="场地/可搜索"
          style="margin-left:30px"
        >
          <el-option :label="item.address_name" :value="item.id" v-for="item in addressArray" :key="item"> </el-option>
        </el-select>
        <el-button type="primary" style="margin-left: 10px" @click="searcht()" v-if="search">筛选</el-button>
        <el-button type="warning" style="margin-left: 30px" @click="tuisearch()" v-else>取消搜索</el-button>
      </div>
      <el-button
        type="primary"
        size="small"
        @click="
          dialogFormVisible = true;
          type = 'add';
          form = {
            gongs: null, // 最小功率
            gonge: null, // 最大功率
            price: null, // 单价
            address_id: null, // 场地id
            star: null, // 最小度数
            end: null, // 最大度数
          };
        "
        >新增挡位
      </el-button>
    </div>
    <el-table :data="tableData" border style=" width: 100%; margin-top: 30px; max-height: 70vh; overflow-y: scroll;">
      <el-table-column label="最小功率" prop="gongs"></el-table-column>
      <el-table-column label="最大功率" prop="gonge"></el-table-column>
      <el-table-column label="最小度数" prop="star"></el-table-column>
      <el-table-column label="最大度数" prop="end"></el-table-column>
      <el-table-column label="单价" prop="price"></el-table-column>
      <el-table-column label="场地" prop="add.address_name"></el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="scope">
          <el-button type="danger" size="small" @click.prevent="deleteRow(scope.$index, tableData)">删除 </el-button>
          <el-button type="primary" size="small" @click="handleClick(scope.row, scope.$index)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="type == 'add' ? '增加挡位' : '编辑挡位'" v-model="dialogFormVisible" width="500px">
      <el-form :model="form">
        <el-form-item label="场地" :label-width="formLabelWidth">
          <el-select
            :disabled="!search"
            v-model="form.address_id"
            filterable
            placeholder="选择场地"
            style="margin-left:00px"
          >
            <el-option :label="item.address_name" :value="item.id" v-for="item in addressArray" :key="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="功率" :label-width="formLabelWidth">
          <el-col :span="11">
            <el-input style="width:100px" autocomplete="off" placeholder="最小功率" v-model="form.gongs"> </el-input>
          </el-col>
          <el-col class="text-center" :span="1" style="margin: 0 0.5rem">~</el-col>
          <el-col :span="11">
            <el-input style="width:100px" autocomplete="off" placeholder="最大功率" v-model="form.gonge"> </el-input>
          </el-col>
          <el-col class="text-center" :span="1" style="margin: 0 0.5rem">W</el-col>
        </el-form-item>
        <el-form-item label="收费标准" :label-width="formLabelWidth">
          <!-- <template> -->
          <el-col :span="20">
            <el-input style="width:222px" autocomplete="off" placeholder="单价" v-model="form.price"> </el-input>
          </el-col>
          <el-col class="text-center" :span="8" style="margin: 0 0.5rem">元 / h</el-col>
          <!-- </template> -->
        </el-form-item>
        <el-form-item label="挡位标准" :label-width="formLabelWidth">
          <!-- <template> -->
          <el-col :span="11">
            <el-input style="width:100px" autocomplete="off" placeholder="最小度数" v-model="form.star"> </el-input>
          </el-col>
          <el-col class="text-center" :span="1" style="margin: 0 0.5rem">~</el-col>
          <el-col :span="11">
            <el-input style="width:100px" autocomplete="off" placeholder="最大度数" v-model="form.end"> </el-input>
          </el-col>
          <el-col class="text-center" :span="1" style="margin: 0 0.5rem">度</el-col>
          <!-- </template> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="addcurse()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { ElMessage } from "element-plus";

import {
  jumpGroupList,
  addjumpgroup,
  jumpGroupedel,
  jumpGroupedit,
  jumpGroupeupd,
  dwList,
  dwAdd,
  dwEdit,
  dwDelete,
  addressList,
} from "/src/request/api";

export default {
  async created() {
    // this.value = this.value + "address_id=" + this.$route.query.address_id;
    // this.address_id = this.$route.query.address_id;
    // this.form.address_id = this.$route.query.address_id;
    this.pagetotal = await this.admList();
    this.pagetotal ? this.$emit("pagetotalClick", this.pagetotal) : "";

    this.initSearch();
  },
  props: ["page", "pagepage"],
  components: {
    QrcodeVue,
  },
  data() {
    return {
      dialogFormVisible: false,
      type: "add",
      pagetotal: 0,
      formLabelWidth: "80px",
      size: 10,
      form: {
        gongs: null, // 最小功率
        gonge: null, // 最大功率
        price: null, // 单价
        address_id: null, // 场地id
        star: null, // 最小度数
        end: null, // 最大度数
      },
      addressListArr: [],
      tableData: [],
      addresssearch: null,
      addressArray: [],
      search: true,
    };
  },
  methods: {
    switchChange(item) {
      jumpGroupeupd({
        id: item.id,
        status: item.status == true ? 1 : 2,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: res.code == 200 ? "success" : "info",
            message: res.msg,
          });
        }
      });
    },

    // 列表
    admList(isSearch = false) {
      //   this.tableData = [];
      return new Promise((resolve, reject) => {
        dwList({
          s: (this.page - 1) * this.pagepage,
          e: this.pagepage,
          address_id: isSearch ? this.addresssearch : undefined,
        }).then((res) => {
          console.log(res);
          resolve(res.list.count);
          this.tableData = res.list.list;
        });
      });
    },

    // 添加 和 编辑 提交
    addcurse() {
      // this.form.star = (+this.form.star).toFixed(8)
      // this.form.end = (+this.form.end).toFixed(8)
      if (this.type == "add") {
        delete this.form.id;
        dwAdd(this.form).then((res) => {
          if (res.code == 200) {
            this.admList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.form = {};
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else if (this.type == "revise") {
        delete this.form.add;
        dwEdit(this.form).then((res) => {
          if (res.code == 200) {
            this.admList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else {
        this.dialogFormVisible = false;
      }
    },

    // 编辑
    handleClick(item, index) {
      this.dialogFormVisible = true;
      this.type = "revise";
      this.form = item;
    },

    //删除
    async deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          dwDelete({ id: rows[index].id }).then((res) => {
            if (res.code == 200) {
              rows.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.$emit("pagetotalClick", this.pagetotal - 1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    searcht() {
      this.search = false;
      this.admList(true);
    },
    tuisearch() {
      this.addresssearch = null;
      this.search = true;
      this.admList();
    },
    initSearch() {
      addressList({
        page: 1,
        page_size: 99999,
      }).then((res) => {
        console.log("场地列表", res.list.address_list);
        if (res.code) {
          this.addressArray = res.list.address_list;
        } else {
          alert("场地接口请求失败！");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 250px;
}

.el-form-item {
  display: flex;
}

.top {
  .l {
    font-size: 20px;
    font-weight: 400;
    color: #545454;
  }

  display: flex;
  justify-content: space-between;
}
</style>
