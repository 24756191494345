<template>
  <div>
    <div class="top" style="display: flex; margin-bottom: 20px">
      <div class="ml" style="display:flex;margin-right:20px;align-item:center">
        <div style="width:60px;margin-top:10px">搜索</div>
        <el-input type="text" placeholder="手机号/用户名" :disabled="!search" v-model="model" width="80px" />
      </div>
      <div class="ml">
        <span style="margin-right: 10px">充值时间</span>
        <el-date-picker
          :disabled="!search"
          v-model="datevalue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>

      <el-button type="primary" style="margin-left: 10px" @click="searcht2()" v-if="search">查询</el-button>
      <el-button type="warning" style="margin-left: 30px" @click="tuisearch2()" v-else>取消搜索</el-button>
    </div>

    <!-- 表格 -->
    <el-table @row-click="rowclick" :data="tableData" border style="width: 100%; max-height: 65vh; overflow-y: scroll">
      <el-table-column prop="user_name" label="用户昵称"></el-table-column>
      <el-table-column prop="user_id" label="用户id"></el-table-column>
      <el-table-column prop="mobile" label="手机号"> </el-table-column>
      <el-table-column prop="price" label="付款金额"></el-table-column>
      <el-table-column prop="createtime" label="时间"></el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          {{ scope.row.pay_status == 1 ? "已支付" : "已退款" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            :disabled="scope.row.pay_status != 1"
            type="warning"
            size="small"
            @click.stop="tks(scope.$index, tableData)"
            >退款
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 退款弹窗2 -->
    <el-dialog v-model="centerDialogVisible2" width="25%" center>
      <div
        style="
          text-align: center;
          width: 100%;
          font-weight: 500;
          font-size: 18px;
        "
      >
        <div style="margin-bottom:20px;">退款</div>
        <div style="margin-bottom:20px;font-size: 16px">退款金额不能大于实际支付金额（{{ showpay }}元）</div>
        <div style="margin-bottom:20px;font-size: 12px;line-height: 24px;">
          提示：单笔订单只能退款一次，无论是不是全额退款， 且用户余额必须大于等于当前退款金额
        </div>
        <el-form :model="{}">
          <el-form-item label="请输入退款金额" :label-width="formLabelWidth">
            <el-input v-model="price"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible2 = false">取消</el-button>
          <el-button type="primary" @click="tkdd()">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { openTheDoorRecord, addressList1, dwChargin, dwRefund } from "../../request/api";
import Moment from "moment";
import { number } from "echarts";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      address_list: [],
      search: true,
      model: "",
      datevalue: ["", ""],
      addressid: "",
      tableData: [],

      centerDialogVisible2: false,
      price: null,
      showpay: null,
      formLabelWidth: "120px",
    };
  },
  async created() {
    addressList1().then((res) => {
      this.address_list = res.list;
    });
    this.datevalue = this.$route.query.datevalue ? this.$route.query.datevalue.split(",") : "";
    this.addressid = this.$route.query.addressid ? Number(this.$route.query.addressid) : "";
    console.log(this.datevalue || this.addressid, this.datevalue, this.addressid);
    if (this.datevalue[0] || this.datevalue[1] || this.addressid) {
      this.search = false;
    }
    this.taocandimens();
  },

  watch: {
    page() {
      this.taocandimens();
    },
    pagepage() {
      this.taocandimens();
    },
  },
  methods: {
    tks(index, rows) {
      this.order_sn = rows[index].order_on;
      this.price = null;
      this.showpay = rows[index].price;
      this.centerDialogVisible2 = true;
    },
    tkdd() {
      const price = parseFloat(this.price);
      console.log(price);
      if (!price || price <= 0) {
        this.$message({
          type: "error",
          message: "请填写退款金额",
        });
        return;
      }
      if (price > this.showpay) {
        this.$message({
          type: "error",
          message: "退款金额不能大于实际支付金额",
        });
        return;
      }
      dwRefund({ order_on: this.order_sn, t_price: this.price }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "退款成功!",
          });
          this.taocandimens();
          this.centerDialogVisible2 = false;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.centerDialogVisible2 = false;
          this.taocandimens();
        }
      });
    },
    async refunds(index, rows) {
      this.$confirm(`此操作将对用户 ${rows[index].user_name} 进行退款, 是否继续？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          dwRefund({ order_on: rows[index].order_on }).then((res) => {
            if (res.code == 200) {
              rows.splice(index, 1);
              this.$message({
                type: "success",
                message: "退款成功!",
              });
              this.taocandimens();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退款",
          });
        });
    },
    rowclick(row) {
      this.$router.push("/Userdetail?id=" + row.user_id);
    },
    searcht2() {
      this.taocandimens();
      this.search = false;
    },
    tuisearch2() {
      this.datevalue = "";
      this.addressid = "";
      this.model = "";
      this.taocandimens();
      this.search = true;
    },

    taocandimens() {
      return new Promise((resolve, reject) => {
        this.tableData = [];
        dwChargin({
          s: (this.page - 1) * this.pagepage,
          e: this.pagepage,
          text: this.model ? this.model : undefined,
          createtime1: this.datevalue[0] ? Moment(this.datevalue[0]).format("YYYY-MM-DD HH:mm:ss") : undefined,
          createtime2: this.datevalue[1] ? Moment(this.datevalue[1]).format("YYYY-MM-DD HH:mm:ss") : undefined,
        }).then((res) => {
          resolve(res.list.count);
          this.$emit("pagetotalClick", res.list.count);
          this.tableData = res.list.list;
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.userexamine {
  /deep/.cell {
    text-align: center;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>
